
import {Box, Flex, Text} from '@chakra-ui/react';


const MainMint = ({accounts, setAccounts}) => {
    

    return (
        
        
        <Flex justify="center" align="bottom" height="81vh" style={{ overflowY: 'auto' }} flex="1" >

         <Box width="800px">
            
                <div>
                    <Text marginTop="0px" fontSize="48px" textShadow="0 5px #000000">ConCats</Text>

                    <Text fontSize="30px" letterSpacing="-5.5%" fontFamily="VT323" textShadow="0 5px 5px #000000"> 
                    Mint your Conflux Cat and join the new <br/>digital reality on Conflux eSpace!
                    </Text>
                </div>

<iframe
    src="https://ipfs.thirdwebcdn.com/ipfs/QmfK9mw9eQKE9vCbtZht9kygpkNWffdwibsJPnCo7MBN4M/erc721.html?contract=0x1Ac572704a0143081919D58Dc74ceBA68D7F7032&chain=%7B%22name%22%3A%22Conflux+eSpace%22%2C%22chain%22%3A%22Conflux%22%2C%22rpc%22%3A%5B%22https%3A%2F%2Fconflux-espace.rpc.thirdweb.com%2F5a9bc94b87f7cbbbfbbc234bf1e07f0adf5f3cf3012c9f26f9fc9820d64df93a%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22CFX%22%2C%22symbol%22%3A%22CFX%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22cfx%22%2C%22chainId%22%3A1030%2C%22testnet%22%3Afalse%2C%22slug%22%3A%22conflux-espace%22%7D&theme=dark&primaryColor=pink"    
    width="550px"
    height="470px"
    style={{maxWidth: '100%'}}
    frameborder="0"
></iframe>


<Text marginTop="10px" fontSize="28px" letterSpacing="-5.5%" fontFamily="VT323" textShadow="0 4px 4px #000000"> 
           --- Mint Price: 10 CFX ---
            </Text>
                    
        <Text marginTop="40px" fontSize="28px" letterSpacing="-5.5%" fontFamily="VT323" textShadow="0 2px 2px #000000"> 
            It’s the year 2029, a world once teeming with life<br/> now lay desolate and forlorn. 
The ruinous consequences of humanity's heedless ways had taken their toll. <br/>
Economies had crumbled, governments had succumbed to corruption, and the world had become a dystopian wasteland.<br/><br/>
But amidst the chaos and despair, a brilliant scientist named Hiro toiled in secret. He stumbled upon a groundbreaking discovery: a virtual world built on Conflux eSpace that could house human consciousness within its digital confines.<br/><br/>
Instead of replicating the mistakes of the past, he chose to create a new reality… a world where the avatars would carry the consciousness of humanity in feline form. <br/><br/>
He saw in the cats a symbol of resilience and hope, creatures that could embody the best parts of humanity without the frailties that had led to its downfall.<br/><br/>
Thus, the ConfluxCats came into being, each one imbued with the consciousness of a human mind, yet still possessing a unique feline personality. <br/><br/>
As the people of the world looked on in wonder, the cats moved through the digital realm, embodying all that was left of the human spirit.<br/><br/>
And so, in the darkest of times, a new world was born, one that promised a brighter future for all who dared to venture within!<br/><br/>
            </Text>
         </Box>

      </Flex>

      
    );
    

};

export default MainMint;



