import React from 'react';
import {Flex, Image, Link} from '@chakra-ui/react';
import Telegram from "./assets/social-media-icons/TG32x32.png";
import Twitter from "./assets/social-media-icons/twitter_32x32.png";


const NavBar = ({accounts, setAccounts}) => {

    return (

        <Flex justify="space-between" aling="center" padding="30px">
        {/* left side - social media */}
        <Flex justify="space-around" width="30%" padding="0 75px">
            <Link target="_blank" href="https://t.me/confluxcats">
                <Image src={Telegram} boxSize="42px" margin="0 15px"></Image>
            </Link>
        
            <Link target="_blank" href="https://twitter.com/confluxcats">
                <Image src={Twitter} boxSize="42px" margin="0 15px"></Image>
            </Link>

        </Flex> 

        {/* right side - secrtions and connect*/}
        <Flex justify="space-around" align="center" width="20%" >
   
       
        </Flex>


        </Flex>
    );

};

export default NavBar;